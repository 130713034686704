import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M10.505 6.61a5.377 5.377 0 0 0-5.386 5.386 5.377 5.377 0 0 0 5.386 5.386 5.377 5.377 0 0 0 5.386-5.386 5.377 5.377 0 0 0-5.386-5.386m0 8.887a3.51 3.51 0 0 1-3.502-3.501 3.505 3.505 0 0 1 3.502-3.502 3.505 3.505 0 0 1 3.501 3.502 3.51 3.51 0 0 1-3.501 3.501m6.862-9.108c0 .699-.562 1.257-1.256 1.257a1.256 1.256 0 1 1 1.256-1.256Zm3.567 1.275c-.08-1.682-.464-3.173-1.697-4.401-1.228-1.228-2.718-1.613-4.401-1.697-1.735-.098-6.933-.098-8.667 0C4.49 1.646 3 2.03 1.767 3.258S.155 5.977.07 7.66c-.098 1.734-.098 6.933 0 8.667.08 1.683.464 3.173 1.697 4.401C3 21.957 4.486 22.341 6.17 22.425c1.734.099 6.933.099 8.667 0 1.683-.08 3.173-.464 4.402-1.697 1.228-1.228 1.612-2.718 1.696-4.401.099-1.734.099-6.928 0-8.663Zm-2.24 10.524a3.55 3.55 0 0 1-1.997 1.997c-1.383.548-4.664.422-6.192.422s-4.814.122-6.193-.422a3.55 3.55 0 0 1-1.996-1.997c-.549-1.383-.422-4.664-.422-6.192s-.122-4.814.422-6.192a3.55 3.55 0 0 1 1.996-1.997c1.383-.549 4.665-.422 6.193-.422s4.814-.122 6.192.422a3.55 3.55 0 0 1 1.997 1.997c.548 1.382.422 4.664.422 6.192s.126 4.814-.422 6.192"
    }, null, -1)
  ])))
}
export default { render: render }